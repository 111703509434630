import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { Schedule } from 'data/calendarEvents';
import dayjs from 'dayjs';
import { useCalendarContext } from 'providers/CalendarProvider';
import { Modal, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SET_CALENDAR_STATE } from 'reducers/CalendarReducer';

const CalendarEventModal = () => {
  const { selectedEvent, calendarDispatch } = useCalendarContext();

  const handleClose = () => {
    calendarDispatch({
      type: SET_CALENDAR_STATE,
      payload: {
        selectedEvent: null
      }
    });
  };

  return (
    <Modal
      centered
      show={!!selectedEvent}
      onHide={handleClose}
      contentClassName="border"
      size="lg"
    >
      <Modal.Header className="ps-card border-bottom border-translucent">
        <div>
          <Modal.Title className="modal-title text-body-highlight mb-0">
            {selectedEvent?.title}
          </Modal.Title>
          {selectedEvent?.extendedProps.organizer && (
            <p className="mb-0 fs-9 mt-1">
              by <Link to="#!">{selectedEvent.extendedProps.organizer}</Link>
            </p>
          )}
        </div>
        <Button className="p-1" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} className="fs-8" />
        </Button>
      </Modal.Header>
      <Modal.Body className="px-card pb-card pt-1 fs-9">
        <Row>
          <Col sm={4} md={4}>
            <img src={selectedEvent?.extendedProps.mealFile} alt="" />
          </Col>
          <Col sm={8} md={8}>
            <div>
              <div
                className={classNames('mt-4', {
                  'border-bottom border-translucent pb-3':
                    selectedEvent?.extendedProps.location
                })}
              >
                <h5 className="mb-0 text-body-secondary">Date and Time</h5>
                <p className="mb-1 mt-2">
                  {dayjs(selectedEvent?.extendedProps.createdAt).format(
                    'dddd, MMMM D, YYYY, h:mm A'
                  )}
                  {selectedEvent?.end &&
                    ` – ${
                      dayjs &&
                      dayjs(selectedEvent?.end)
                        .subtract(1, 'day')
                        .format('dddd, MMMM D, YYYY, h:mm A')
                    }`}
                </p>
              </div>
              {selectedEvent?.extendedProps.description && (
                <div className="mt-3 border-bottom border-translucent pb-3">
                  <h5 className="mb-0 text-body-secondary">Description</h5>
                  <p className="mb-0 mt-2">
                    {selectedEvent.extendedProps.description
                      .split(' ')
                      .slice(0, 30)
                      .join(' ')}
                  </p>
                </div>
              )}
              <div
                className={classNames('mt-4', {
                  'border-bottom border-translucent pb-3':
                    selectedEvent?.extendedProps.location
                })}
              >
                <h5 className="mb-0 text-body-secondary">Items</h5>
                <p className="mb-1 mt-2">
                  {selectedEvent?.extendedProps.nutrient ? (
                    <ol>
                      {selectedEvent?.extendedProps.nutrient.map(
                        (nutrient: any, i: number) => (
                          <li key={i}>
                            <h5>
                              {nutrient.name} {nutrient.quantity}g
                            </h5>
                            <ul className="mb-1 mt-2">
                              {nutrient?.nutritionFact.map(
                                (fact: any, i: number) => (
                                  <li key={i}>
                                    {fact.nutrientName}: {fact.value}
                                    {fact.unitName}:
                                  </li>
                                )
                              )}
                            </ul>
                          </li>
                        )
                      )}
                    </ol>
                  ) : null}
                </p>
              </div>

              {selectedEvent?.extendedProps.location && (
                <div className="mt-4 ">
                  <h5 className="mb-0 text-body-secondary">Location</h5>
                  <p
                    className="mb-0 mt-2"
                    dangerouslySetInnerHTML={{
                      __html: selectedEvent.extendedProps.location
                    }}
                  ></p>
                </div>
              )}
              {selectedEvent?.extendedProps.schedules && (
                <div className="mt-3">
                  <h5 className="mb-0 text-body-secondary">Schedule</h5>
                  <ul className="list-unstyled timeline mt-2 mb-0">
                    {selectedEvent.extendedProps.schedules.map(
                      (schedule: Schedule) => (
                        <li key={schedule.title}>{schedule.title}</li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CalendarEventModal;
